import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { TaskStatusEnum } from '../../../.generated/globalTypes';

type TaskStatusBadgeProps = {
  status: TaskStatusEnum;
  className?: string;
};

export const getStatusColor = (status: TaskStatusEnum) => {
  switch (status) {
    case TaskStatusEnum.APPROVED:
    case TaskStatusEnum.COMPLETED:
      return 'bg-success';
    case TaskStatusEnum.DENIED:
      return 'bg-danger';
    default:
      return 'bg-primary';
  }
};

function TaskStatusBadge({ status, className = undefined }: TaskStatusBadgeProps) {
  const { t } = useTranslation('tasks');

  const statuses = t(`statuses.${status}`).toLowerCase().split(' - ');

  return (
    <>
      {statuses.map((subStatus) => (
        <span key={subStatus} className={classNames('badge status', getStatusColor(status), className)}>
          {subStatus}
        </span>
      ))}
    </>
  );
}

export default TaskStatusBadge;
