import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';

type ShowMoreButtonProps = {
  totalItems: number;
  pageSize: number;
  showMore: () => void;
  hasSettled: boolean;
  isLoading: boolean;
};

export default function ShowMoreButton({
  totalItems,
  pageSize,
  showMore,
  hasSettled,
  isLoading
}: ShowMoreButtonProps) {
  const { t } = useTranslation();
  const [scrollIntoPx, setScrollIntoPx] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollIntoPx && hasSettled) {
      window.scrollTo({ top: scrollIntoPx, behavior: 'instant' as ScrollBehavior });
      setScrollIntoPx(0);
    }
  }, [hasSettled, pageSize, totalItems, scrollIntoPx]);

  return (
    <div className="text-center" ref={ref}>
      {!isLoading && totalItems > pageSize && (
        <button
          type="button"
          className="btn btn-primary btn-sm mb-3"
          onClick={() => {
            showMore();
            if (ref.current) setScrollIntoPx(ref.current.offsetTop - 200);
          }}
        >
          {t('buttons.loadMore')}
        </button>
      )}
    </div>
  );
}
