import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

type TaskArchiveBadgeProps = {
  archived: boolean;
  className?: string;
};

function TaskArchiveBadge({ archived, className = undefined }: TaskArchiveBadgeProps) {
  const { t } = useTranslation('tasks');

  if (!archived) return null;
  return (
    <span className={classNames('badge  bg-danger', className)}>{t('labels.archive').toLowerCase()}</span>
  );
}

export default TaskArchiveBadge;
