import classNames from 'classnames';

type CardActionsProps = { className?: string };

export default function CardActions({
  children,
  className = 'py-1'
}: React.PropsWithChildren<CardActionsProps>) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames('card-actions w-100 h-100 d-flex align-items-end justify-content-end', className)}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
      <style jsx>{`
        .card-actions :global(.btn-light.dropdown-toggle) {
          border: 1px solid #eef2f7;
          border-radius: 2.4px;
        }
        .card-actions > :global(.btn) {
          box-shadow: none;
        }
        .card-actions :global(.dropdown-toggle) {
          box-shadow: 0 2px 6px 0 rgba(114, 114, 114, 0.15) !important;
        }
        :global(.dropdown-menu) {
          border-radius: 0;
        }
        :global(.dropdown-menu .mb-1) {
          margin-bottom: 0 !important;
        }
      `}</style>
    </div>
  );
}
