import { TaskStatusEnum } from '../../../.generated/globalTypes';
import TaskArchiveBadge from './TaskArchiveBadge';
import TaskStatusBadge from './TaskStatusBadge';

type TaskBadgesProps = {
  status: TaskStatusEnum;
  archived: boolean;
  className?: string;
};

function TaskBadges({ status, archived, className = undefined }: TaskBadgesProps) {
  return (
    <>
      <TaskStatusBadge status={status} className={className} />
      <TaskArchiveBadge archived={archived} className={className} />
    </>
  );
}

export default TaskBadges;
