import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import classNames from 'classnames';
import CancelConfirmationModal from './CancelConfirmationModal';

type CancelButtonProps = { onConfirm: Function; className?: string; showTitle?: boolean };

export default function CancelButton({ onConfirm, className, showTitle = true }: CancelButtonProps) {
  const { t } = useTranslation('common');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button type="button" className={classNames('btn btn-danger', className)} onClick={handleShow}>
        {showTitle && t('buttons.cancel')}
      </button>
      <CancelConfirmationModal onConfirm={onConfirm} onClose={handleClose} show={show} />
    </>
  );
}
