import { useTranslation } from 'next-i18next';

type TotalDisplayProps = {
  totalItems: number;
};

function TotalDisplay({ totalItems }: TotalDisplayProps) {
  const { t } = useTranslation('common');

  return <div className="page-count-display mb-2 text-end">{t('messages.total', { count: totalItems })}</div>;
}

export default TotalDisplay;
