import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import useToast from '~/features/shared/hooks/useToast';

import CancelTaskMutation from './graphql/CancelTaskMutation.gql';
import { TaskStatusEnum } from '~/.generated/globalTypes';

type CancelTaskHookArgs = {
  onCompleted?: Function;
};

export default function useCancelTask({ onCompleted }: CancelTaskHookArgs = {}) {
  const { toast } = useToast();
  const { t } = useTranslation('tasks');
  const [cancelMutation, { loading: canceling, error: cancelError }] = useMutation(CancelTaskMutation, {
    refetchQueries: ['TasksTableQuery'],
    onCompleted: () => {
      toast(t('messages.canceled'), { variant: 'success' });
      if (onCompleted) onCompleted();
    },
    onError: () => toast(t('errors.cancel'), { variant: 'error' })
  });

  const cancelTask = useCallback(
    (id: number) => {
      cancelMutation({
        variables: { where: { id }, input: { status: TaskStatusEnum.CANCELED } }
      });
    },
    [cancelMutation]
  );

  return {
    cancelTask,
    canceling,
    cancelError
  };
}
